import moment from "moment/moment";

export const isMomentDate = (obj) => {
    return moment.isMoment(obj);
}

export const clientTzOffset = () => {
    return new Date().getTimezoneOffset();
}

export const now = () => {
    return moment();
}

export const nowUtc = () => {
    return moment.utc();
}

export const toUtcDatetimeString = (datetime_utc) => {
    if (datetime_utc === undefined || datetime_utc === null || datetime_utc === '') {
        return "";
    }
    const m = moment(datetime_utc);
    m.utc();
    return m.toISOString();
}

export const toRelativeDatetime = (datetime_utc) => {
    const m = moment(datetime_utc);
    return m.fromNow();
}

export const toTimezoneDatetime = (datetime_utc, tz_offset_in_minutes) => {
    if (datetime_utc === undefined || datetime_utc === null || datetime_utc === '') {
        return undefined;
    }
    const m = moment.utc(datetime_utc);
    if (tz_offset_in_minutes) {
        m.utcOffset(-tz_offset_in_minutes);
    }
    return m;
}

export const toDateString = (moment_obj) => {
    return moment_obj.format("YYYY-MM-DD");
}

export const copyYearMonthDay = (from, to) => {
    if (isMomentDate(from) === false) {
        throw new Error("From date must be moment object.");
    }
    if (isMomentDate(to) === false) {
        throw new Error("To date must be moment object.");
    }
    to.year(from.year());
    to.month(from.month()); // (0-11)
    to.date(from.date());
    return to;
}

export const copyHourMinutesNoSeconds = (fromDate, toDate) => {
    if (isMomentDate(fromDate) === false) {
        throw new Error("fromDate must be moment object.");
    }
    if (isMomentDate(toDate) === false) {
        throw new Error("toDate must be moment object.");
    }
    toDate.hour(fromDate.hour());
    toDate.minute(fromDate.minute());
    toDate.seconds(0)
    return toDate;
}

export const getPastDates = (daysBack, startDate=moment()) => {
    let dates = []
    let d = startDate;
    while (daysBack > 0) {
        let dayOfMonth = parseInt(d.format("D"));
        const date_obj = {
            "date": toDateString(d),
            "dayOfWeek": parseInt(d.format("E")),
            "dayOfWeekName": d.format("ddd"),
            "dayOfMonth": dayOfMonth,
            "label": dayOfMonth === 1 ? d.format("MMM - ddd D") : d.format("ddd D")
        }
        dates.push(date_obj);
        d = d.subtract(1, 'days');
        daysBack -= 1;
    }
    return dates;
}


