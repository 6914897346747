import React, {Component} from "react";
import {connect} from "react-redux";

import cloneDeep from 'lodash/cloneDeep';

import Card from '@mui/material/Card';
import {Box, CardContent, Typography} from "@mui/material";
import './logsummary.component.css';

import {fetchLogs} from "../actions/logs";
import {isMomentDate} from "../utils/dateutils";


function mapStateToProps(state) {
    return {
        logsState: state.logs
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchLogs: (filter_term) => dispatch(fetchLogs(filter_term))
    }
}

export class LogSummary extends Component {

    tz_offset = new Date().getTimezoneOffset();

    onFilterTermChanged(filter_term) {
        this.props.fetchLogs(filter_term);
    }

    componentDidMount() {
        this.props.fetchLogs(this.props.filter_term);
        // TODO bit weird to register listener
        this.props.registerFilterTermListener((filter_term) => this.onFilterTermChanged(filter_term));
    }

    formatTime(log_item) {
        if (!isMomentDate(log_item.event_datetime_utc)) {
            throw new Error("event_datetime_utc is not a moment obj");
        }
        let res = "";
        if (log_item.event_datetime_end_utc) {
            let delta = log_item.event_datetime_end_utc.diff(log_item.event_datetime_utc, "hours", true);
            let delta_rounded = Math.round(delta * 100) / 100;
            res += delta_rounded + "h # ";
        }
        res += log_item.event_datetime_utc.format("LT");
        if (log_item.event_datetime_end_utc) {
            res += " - " + log_item.event_datetime_end_utc.format("LT");
        }
        if (log_item.event_datetime_tz_offset !== this.tz_offset) {
            res += " (GMT" + log_item.event_datetime_utc.format("Z") + ")";
        }
        return res;
    }

    formatDate(log_item) {
        return log_item.event_datetime_utc.format("L");
    }

    getAugmentedLogs() {
        // inject month breaks
        let logs = cloneDeep(this.props.logsState.logs);
        let augmentedLog = [];
        for (let i = 0; i < logs.length; i++) {
            const thisLog = logs[i];
            augmentedLog.push(thisLog);
            if (i < logs.length-1) {
                const nextLog = logs[i+1];
                if (thisLog.event_datetime_utc.month() !== nextLog.event_datetime_utc.month()) {
                    augmentedLog.push({
                        "break": "month",
                        "value": nextLog.event_datetime_utc.format('MMMM')
                    })
                }
            }
        }
        return augmentedLog;
    }

    render() {
        if (this.props && this.props.logsState && this.props.logsState.logs) {
            let logs = this.getAugmentedLogs();
            return (
                <Box sx={{ mt: 1 }}>
                    {logs.map(log => (
                        log.break ? (
                                <Typography key={log.value} variant="h5" component="h1">
                                    {log.value}
                                </Typography>
                            ) : (
                                <Card key={log.id} sx={{ mt: 1, mb:1 }} onClick={() => this.props.onItemSelect(log)}>
                                    <CardContent>
                                        {log.hashtag || log.timeInfo ?
                                            <Typography variant="body2" sx={{ mb: 1 }} component="div"color="primary.main">
                                                {log.hashtag} {log.timeInfo}
                                            </Typography> : null}
                                        <Typography variant="body1" component="div" color="text.primary">
                                            {log.textBody}
                                        </Typography>
                                        <Typography variant="caption" sx={{ mt: 1 }} component="div" color="text.secondary">
                                            {this.formatTime(log)} | {this.formatDate(log)}
                                        </Typography>
                                        <Typography variant="caption" component="div" color="info.main">
                                            {log.relativeDatetime}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            )
                    ))}
                    {this.props.logsState.logs.length === 0 ? (
                        <Card sx={{ mt: 2, mb:1 }}>
                            <CardContent>
                                <Typography sx={{mt: 1}} variant="body1">No logs found.</Typography>
                            </CardContent>
                        </Card>) : ''}
                </Box>
            );
        }
        return (
            <Card sx={{ mt: 2, mb:1 }}>
                <CardContent>
                    <Typography sx={{mt: 1}} variant="body1">No logs found.</Typography>
                </CardContent>
            </Card>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogSummary);