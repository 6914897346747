import React, {Component} from "react";
import {connect} from "react-redux";

import {Box, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";

import "./home.component.css";

import LogSummary from "./logsummary.component";
import EventDateTimePicker from "./eventdatetime.component";
import NotLoggedIn from "./notloggedin.component";

import {updateLog} from "../actions/logs";
import {refreshUser} from "../actions/user";


function mapStateToProps(state) {
    return {
        userState: state.user,
        logsState: state.logs
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateLog: (data) => dispatch(updateLog(data)),
        refreshUser: () => dispatch(refreshUser())
    }
}

export class Search extends Component {

    state = {
        show: false,
        update_item: {},
        update_text: '',
        custom_datetime: false
    }

    filterTermChangeCallback = () => { console.log("listener not set"); };

    componentDidMount() {
        this.props.refreshUser(); // supports page reload
    }

    handleCloseModal() {
        this.setState({
            show: false
        });
    }

    showItemForUpdate(item) {
        this.setState({
            show: true,
            update_item: item,
            update_text: item.text
        });
    }

    handleItemUpdate() {
        const updateItem = {
            ...this.state.update_item,
            text: this.state.update_text
        }
        this.props.updateLog(updateItem);
        this.handleCloseModal();
    }

    registerFilterTermListener(listener) {
        this.filterTermChangeCallback = listener;
    }

    onEventDateTimeChanged(data) {
        const update = {...this.state.update_item};
        update.event_datetime_utc = data.event_datetime_utc;
        update.event_datetime_end_utc = data.event_datetime_end_utc;
        update.event_datetime_tz_offset = data.event_datetime_tz_offset;
        this.setState({
            update_item: update
        });
    }

    render() {
        let content;
        if (this.props.userState.user && this.props.userState.user.name) {
            content = (
                <div className="home-inner">
                    <Card sx={{ mt: 2, p: 2 }}>
                        <TextField id="outlined-basic" variant="outlined"
                                   autoFocus
                                   fullWidth
                                   value={this.state.filter_term}
                                   placeholder="Filter logs ..."
                                   onChange={e => this.filterTermChangeCallback(e.target.value)} />
                    </Card>
                    <LogSummary registerFilterTermListener={(listener) => this.registerFilterTermListener(listener)} onItemSelect={(item) => this.showItemForUpdate(item)}/>
                </div>
            )
        } else {
            content = (
                <div className="auth-inner">
                    <NotLoggedIn />
                </div>
            )
        }
        return (
            <div className="main-wrapper">

                {content}

                <Dialog fullWidth open={this.state.show} onClose={() => this.setState({ show: false })}>
                    <DialogTitle>Update Log</DialogTitle>
                    <DialogContent>
                        <TextareaAutosize
                            style={{ width: '100%', padding: '0.5em' }}
                            autoFocus
                            aria-label="minimum height"
                            minRows={5}
                            defaultValue={this.state.update_text}
                            onChange={e => this.setState({ update_text: e.target.value})}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    this.addNote();
                                }
                            }}
                        />
                        <Box sx={{ mt: 2 }}>
                            <EventDateTimePicker
                                key={this.state.update_item.id}
                                initialItem={this.state.update_item}
                                onChange={(data) => this.onEventDateTimeChanged(data)} />
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ show: false })}>Cancel</Button>
                        <Button onClick={() => this.handleItemUpdate()}>Update</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);