import React, {Component} from "react";
import {connect} from "react-redux";

import {fetchMeasurements} from "../actions/measurements";
import {refreshUser} from "../actions/user";
import {toDataFrame} from "../utils/measurementutils";
import {getPastDates} from "../utils/dateutils";
import {registry} from "../renderer";
import NotLoggedIn from "./notloggedin.component";
import Loading from "./loading.component";
import "./stats.component.css";


function mapStateToProps(state) {
    return {
        userState: state.user,
        measurementsState: state.measurements
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchMeasurements: () => dispatch(fetchMeasurements()),
        refreshUser: () => dispatch(refreshUser())
    }
}

export class Stats extends Component {

    state = {
        data_frame: undefined
    }

    componentDidMount() {
        // supports page reload
        this.props.fetchMeasurements().then(measurements => {
            const df = this.prepareData(measurements);
            this.setState({ data_frame: df} );
        });
    }

    prepareData(measurements) {
        let df = toDataFrame(measurements);
        df.dates = getPastDates(300);
        return df;
    }

    render() {
        const isNewWeekCss = (day) => { return day === 1 ? ' m-row-break' : ''; }
        const isWeekendCss = (day) => { return day > 5 ? ' m-row-weekend' : ''; }
        const isNewMonthCss = (monthDay) => { return monthDay === 1 ? ' m-row-new-month' : ''; }

        let content;
        if (this.props.userState.user && this.props.userState.user.name) {
            if (this.state.data_frame) {
                const df = this.state.data_frame;
                content = (
                    <div className="home-inner">
                        <div className="m-grid">
                                <div className="m-row">
                                    <div className="m-row-label"></div>
                                    {df.columns.map((c, hidx) => (
                                        <div key={'hc'+hidx} className="m-header">{registry[c].getLabelEmoji()}</div>
                                    ))}
                                </div>
                            {df.dates.map((_date, idx_row) => (
                                <div key={'r'+idx_row} className={'m-row' + isNewWeekCss(_date.dayOfWeek) + isNewMonthCss(_date.dayOfMonth)}>
                                    <div className={'m-row-container' + isWeekendCss(_date.dayOfWeek)}>
                                        <div className='m-row-label'>
                                            {_date.label}
                                        </div>
                                        {df.columns.map((c, idx_col) => (
                                            <div key={'r'+idx_row+'c'+idx_col} className='m-cell-container'>
                                                <div className={'m-cell ' + registry[c].getCssClass(df.data[c][_date.date])}></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            } else {
                content = (
                    <div className="auth-inner">
                        <Loading />
                    </div>
                )
            }
        } else {
            content = (
                <div className="auth-inner">
                    <NotLoggedIn />
                </div>
            )
        }
        return (
            <div className="main-wrapper">
                {content}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stats);