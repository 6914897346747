import {combineReducers} from "redux";
import userReducer from "./user";
import logsReducer from "./logs";
import measurementsReducer from "./measurements";
import geoReducer from "./geo";

const rootReducer = combineReducers({
    user: userReducer,
    logs: logsReducer,
    measurements: measurementsReducer,
    geo: geoReducer
});

export default rootReducer;