import {
    LOGS_FETCH_STARTED,
    LOGS_FETCH_SUCCESS,
    LOGS_FETCH_FAILURE,
    LOGS_POST_STARTED,
    LOGS_POST_SUCCESS,
    LOGS_POST_FAILURE,
    LOGS_UPDATE_STARTED,
    LOGS_UPDATE_SUCCESS,
    LOGS_UPDATE_FAILURE,
    CHECKIN_POST_STARTED,
    CHECKIN_POST_SUCCESS,
    CHECKIN_POST_FAILURE,
    CHECKIN_FETCH_STARTED,
    CHECKIN_FETCH_SUCCESS, CHECKIN_FETCH_FAILURE
} from "./index";

import {getAxios} from '../utils/apiutils';
import Dexie from 'dexie';

import {toUtcDatetimeString} from "../utils/dateutils";


export const db = new Dexie('memyself');
db.version(1).stores({
    logs: '++id, creation_datetime, event_datetime_utc, event_datetime_tz_offset, lat_lng, text, user_id'
});

const online = true;

// fetch logs

export const fetchLogs = (filter_term) => {
    return dispatch => {
        dispatch(fetchLogsStarted());
        let data = {
            'filter_term': filter_term,
            'nr_logs': 100
        };
        return getAxios().then(instance => {
            console.log("fetching logs");
            return instance.post('log_query', data)
                .then(
                    res => { dispatch(fetchLogsSuccess(res.data.logs)); }
                ).catch(
                    err => { dispatch(fetchLogsFailure(err.message)); }
                )
            }
        ).catch(
            err => {
                console.log("failed to get axios instance", err);
                dispatch(fetchLogsFailure(err.message));
            }
        )
    }
}

const fetchLogsStarted = () => {
    return {
        type: LOGS_FETCH_STARTED
    }
}

const fetchLogsSuccess = (data) => {
    return {
        type: LOGS_FETCH_SUCCESS,
        payload: data
    }
}

const fetchLogsFailure = (err) => {
    return {
        type: LOGS_FETCH_FAILURE,
        payload: err
    }
}


// post log

export const postLog = (data) => {
    return dispatch => {
        dispatch(postLogStarted());
        const now = new Date();
        const event_datetime_utc = toUtcDatetimeString(data.event_datetime_utc);
        const event_datetime_end_utc = toUtcDatetimeString(data.event_datetime_end_utc);
        const event_datetime_tz_offset = data.event_datetime_tz_offset ? data.event_datetime_tz_offset : now.getTimezoneOffset();
        const post = {
            text: data.text,
            event_datetime_utc,
            event_datetime_end_utc,
            event_datetime_tz_offset,
            lat_lng: data.lat_lng
        }
        if (online) {
            return getAxios().then(instance => {
                return instance.post('log', post).then(
                    res => {
                        dispatch(postLogSuccess(res.data));
                        return Promise.resolve(res.data);
                    }
                ).catch(
                    err => {
                        dispatch(postLogFailure(err.message));
                        return Promise.reject(err);
                    }
                )
            }).catch(
                err => {
                    dispatch(postLogFailure(err.message));
                    return Promise.reject(err);
                }
            )
        } else {
            db.logs.add(post).then(
                res => {
                    dispatch(postLogSuccess({
                        log: post
                    }));
                }
            ).catch(
                err => {
                    dispatch(postLogFailure(err));
                }
            );
        }
    }
}

const postLogStarted = () => {
    return {
        type: LOGS_POST_STARTED
    }
}

const postLogSuccess = (data) => {
    return {
        type: LOGS_POST_SUCCESS,
        payload: data
    }
}

const postLogFailure = (err) => {
    return {
        type: LOGS_POST_FAILURE,
        payload: err
    }
}


// update log

export const updateLog = (data) => {
    return dispatch => {
        dispatch(updateLogStarted());
        if (online) {
            const event_datetime_utc = toUtcDatetimeString(data.event_datetime_utc);
            const event_datetime_end_utc = toUtcDatetimeString(data.event_datetime_end_utc);
            const event_datetime_tz_offset = data.event_datetime_tz_offset;
            const post = {
                id: data.id,
                user_id: data.user_id,
                text: data.text,
                event_datetime_utc,
                event_datetime_end_utc,
                event_datetime_tz_offset,
                lat_lng: data.lat_lng
            }
            getAxios().then(instance => {
                instance.put('log', post).then(
                    res => {
                        dispatch(updateLogSuccess(res.data));
                    }
                ).catch(
                    err => {
                        dispatch(updateLogFailure(err.message));
                    }
                )
            }).catch(
                err => {
                    dispatch(updateLogFailure(err.message));
                }
            )
        } else {
            db.logs.put(data).then(
                res => {
                    dispatch(updateLogSuccess({
                        log: data
                    }));
                }
            ).catch(
                err => {
                    dispatch(updateLogFailure(err));
                }
            );
        }
    }
}

const updateLogStarted = () => {
    return {
        type: LOGS_UPDATE_STARTED
    }
}

const updateLogSuccess = (data) => {
    return {
        type: LOGS_UPDATE_SUCCESS,
        payload: data
    }
}

const updateLogFailure = (err) => {
    return {
        type: LOGS_UPDATE_FAILURE,
        payload: err
    }
}


// checkins

export const fetchCheckins = (date) => {
    return dispatch => {
        dispatch(fetchCheckinsStarted());
        let data = {
            'checkin_date': date
        };
        return getAxios().then(instance => {
            instance.post('log_query', data).then(
                res => {
                    dispatch(fetchCheckinsSuccess(res.data.checkins));
                    return Promise.resolve(res.data.checkins);
                }
            ).catch(
                err => {
                    dispatch(fetchCheckinsFailure(err.message));
                    return Promise.reject(err);
                }
            )
        }).catch(
            err => {
                dispatch(fetchCheckinsFailure(err.message));
                return Promise.reject(err);
            }
        )
    }
}

const fetchCheckinsStarted = () => {
    return {
        type: CHECKIN_FETCH_STARTED
    }
}

const fetchCheckinsSuccess = (data) => {
    return {
        type: CHECKIN_FETCH_SUCCESS,
        payload: data
    }
}

const fetchCheckinsFailure = (err) => {
    return {
        type: CHECKIN_FETCH_FAILURE,
        payload: err
    }
}

export const postCheckin = (data) => {
    return dispatch => {
        dispatch(postCheckinStarted());

        // add timestamp
        const post = {
            ...data,
            checkin_datetime_utc: toUtcDatetimeString(data.checkin_datetime_utc)
        }

        if (online) {
            return getAxios().then(instance => {
                instance.post('checkin', post).then(
                        res => {
                            dispatch(postCheckinSuccess(res.data));
                            return Promise.resolve(res);
                        }
                    ).catch(
                        err => {
                            dispatch(postCheckinFailure(err.message));
                            return Promise.reject(err);
                        }
                    )
            }).catch(
                err => {
                    dispatch(postCheckinFailure(err.message));
                    return Promise.reject(err);
                }
            )
        } else {
            throw new Error("not implemented");
        }
    }
}

const postCheckinStarted = () => {
    return {
        type: CHECKIN_POST_STARTED
    }
}

const postCheckinSuccess = (data) => {
    return {
        type: CHECKIN_POST_SUCCESS,
        payload: data
    }
}

const postCheckinFailure = (err) => {
    return {
        type: CHECKIN_POST_FAILURE,
        payload: err
    }
}