import React, {Component} from "react";
import "./eventdatetime.component.css";

import TextField from "@mui/material/TextField";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import TimezonePicker from "./timezone.component";

import moment from "moment/moment";
import {copyHourMinutesNoSeconds, copyYearMonthDay} from "../utils/dateutils";


class EventDateTimePicker extends Component {


    constructor(props) {
        super(props)

        this.state = {
            event_date: moment(),
            event_time: moment(),
            event_tz_offset: new Date().getTimezoneOffset(), // Note: we are not using moment offsets
            event_end_time_set: false,
            event_end_time: undefined
        }

        if (props.initialItem) {
            const item = props.initialItem;

            copyYearMonthDay(item.event_datetime_utc, this.state.event_date);
            copyHourMinutesNoSeconds(item.event_datetime_utc, this.state.event_time);
            this.state.event_tz_offset = item.event_datetime_tz_offset;

            if (item.event_datetime_end_utc !== undefined) {
                this.state.event_end_time = moment();
                copyHourMinutesNoSeconds(item.event_datetime_end_utc, this.state.event_end_time);
                this.state.event_end_time_set = true;
            }
        }
    }

    onDateChanged(new_date) {
        const changed = moment(new_date.toDate()); // Note: mui bug https://github.com/mui/material-ui-pickers/issues/359
        console.log("onDateChanged", changed);
        this.setState({
            event_date: changed
        }, () => {
            this.notifyOnChangeCallback();
        });
    }

    onTimeChanged(new_time) {
        const changed = moment(new_time.toDate());
        console.log("onTimeChanged", changed);
        this.setState({
            event_time: changed
        }, () => {
            this.notifyOnChangeCallback();
        });
    }

    onEndTimeChanged(new_time) {
        // TODO alert if end time before start time
        const changed = moment(new_time.toDate());
        console.log("onEndTimeChanged", changed);
        this.setState({
            event_end_time_set: true,
            event_end_time: changed
        }, () => {
            this.notifyOnChangeCallback();
        });
    }

    onTimezoneChanged(new_timezone) {
        console.log("onTimezoneChanged", new_timezone);
        this.setState({
            event_tz_offset: new_timezone.offset
        }, () => {
            this.notifyOnChangeCallback();
        });
    }

    notifyOnChangeCallback() {
        // assemble new time object
        const event_datetime_utc = this.state.event_date.clone();
        event_datetime_utc.utcOffset(-this.state.event_tz_offset, true); // Note: inverse moment vs. Date offset

        copyYearMonthDay(this.state.event_date, event_datetime_utc);
        copyHourMinutesNoSeconds(this.state.event_time, event_datetime_utc);

        let event_datetime_end_utc = undefined;
        if (this.state.event_end_time_set) {
            event_datetime_end_utc = event_datetime_utc.clone();
            copyHourMinutesNoSeconds(this.state.event_end_time, event_datetime_end_utc);
        }

        this.props.onChange({
            event_datetime_utc,
            event_datetime_end_utc,
            event_datetime_tz_offset: this.state.event_tz_offset
        })
    }

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className={'datetime-container'}>
                    <div className="form-row">
                        <DatePicker
                            className={'datetime-elem'}
                            label="Event date"
                            value={this.state.event_date}
                            onChange={(dt) => this.onDateChanged(dt)}
                            renderInput={(params) => <TextField sx={{ml: 0.5, mr: 1, flexGrow: 1}} {...params} />}
                        />
                    </div>
                    {!this.props.dateOnly ? (
                        <div>
                            <div className="form-row">
                                <TimePicker
                                    className={'datetime-elem'}
                                    label="Start time"
                                    value={this.state.event_time}
                                    onChange={(dt) => this.onTimeChanged(dt)}
                                    renderInput={(params) => <TextField sx={{ml: 0.5, mr: 1, flexGrow: 1}} {...params} />}
                                />
                                <TimePicker
                                    className={'datetime-elem'}
                                    label="End time (optional)"
                                    value={this.state.event_end_time}
                                    onChange={(dt) => this.onEndTimeChanged(dt)}
                                    renderInput={(params) => <TextField sx={{flexGrow: 1}} {...params} />}
                                />
                            </div>
                            <div className="form-row">
                                <TimezonePicker initialOffsetInMinutes={this.state.event_tz_offset}
                                                onTimezoneChanged={(tz) => this.onTimezoneChanged(tz)}/>
                            </div>
                        </div>
                    ) : '' }
                </div>
            </LocalizationProvider>
        )
    }

}

export default EventDateTimePicker;