import React, {Component} from "react";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Slider, Typography} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import "./home.component.css";


export class CheckinItemSlider extends Component {

    state = {
        selectedValue: -1,
        slider_step_description: 'Please rate ...',
        show_add_note: false,
        tmp_note: '',
        notes: []
    }

    componentDidMount() {
        // init notes from item
        this.setState({
            notes: this.props.item.notes ? this.props.item.notes : []
        })
    }

    sliderValuetext(value) {
        const marks = this.props.item.options;
        for (let i = 0; i < marks.length; i++) {
            if (marks[i].value === value) {
                return marks[i].text;
            }
        }
        return '-';
    }

    onValueChanged(value) {
        this.setState({
            selectedValue: value.value
        });
        // update description
        const marks = this.props.item.options;
        for (let i = 0; i < marks.length; i++) {
            if (marks[i].value === value.value) {
                this.setState({
                    slider_step_description: marks[i].description
                });
            }
        }
        // notify component change listener
        this.props.onChange({
            "type": "slider",
            "value": value.value
        });
    }

    showAddNoteDialog(open) {
        this.setState({
            show_add_note: open
        })
    }

    addNote() {
        const new_notes = [...this.state.notes, this.state.tmp_note];
        this.setState({
            notes: new_notes,
            show_add_note: false
        });
        this.props.onChange({
            "type": "notes",
            "value": new_notes
        });
    }

    clearNotes() {
        this.setState({
            notes: []
        });
        this.props.onChange({
            "type": "notes",
            "value": []
        });
    }

    render() {
        const options = this.props.item.options;
        return (
            <Box sx={this.props.sx}>
                <Slider
                    aria-label="Custom marks"
                    defaultValue={this.props.defaultValue}
                    marks={options}
                    onChange={(e) => this.onValueChanged(e.target)}
                    step={1}
                    min={1}
                    max={options.length}
                    valueLabelDisplay="auto"
                    valueLabelFormat={(value) => this.sliderValuetext(value)}/>

                <Typography sx={{ mb: 1 }} variant="body1">{this.state.slider_step_description}</Typography>

                {this.state.notes.length > 0 ? <Paper variant="outlined" sx={{ pl: 1}} >
                    <Typography variant="overline">Notes</Typography>
                    <ul>
                        {this.state.notes.map((note, index) => (
                            <li key={index} variant="body2">{note}</li>
                        ))}
                    </ul>
                </Paper> : null}

                <Button size="small" variant="text" startIcon={<AddIcon />}
                        onClick={() => this.showAddNoteDialog(true)}>Add Note</Button>
                {this.state.notes.length > 0 ? <Button size="small" variant="text" style={{ color: 'grey' }}
                        onClick={() => this.clearNotes()}>Clear</Button>: null}

                <Dialog fullWidth open={this.state.show_add_note} onClose={() => this.showAddNoteDialog(false)}>
                    <DialogTitle>Note</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="note"
                            // label="note"
                            fullWidth
                            variant="standard"
                            onChange={(e) => this.setState({tmp_note: e.target.value})}
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    ev.preventDefault();
                                    this.addNote();
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.showAddNoteDialog(false)}>Cancel</Button>
                        <Button onClick={() => this.addNote()}>Add</Button>
                    </DialogActions>
                </Dialog>
            </Box>

        )
    }
}

export default CheckinItemSlider;