import React, {Component} from "react";
import {connect} from "react-redux";

import {
    Alert,
    Box, FormControlLabel,
    Paper, Snackbar,
    Step,
    StepContent,
    StepLabel,
    Stepper, Switch,
    Typography
} from "@mui/material";
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';

import "./home.component.css";

import NotLoggedIn from "./notloggedin.component";
import CheckinItemSlider from "./checkinitem.slider.component";

import {requestGeolocation} from "../actions/geo";
import {refreshUser} from "../actions/user";
import {postCheckin} from "../actions/logs";
import {getCheckinData} from "../utils/checkinutils";
import {clientTzOffset, now} from "../utils/dateutils";
import EventDateTimePicker from "./eventdatetime.component";


function mapStateToProps(state) {
    return {
        userState: state.user,
        geoState: state.geo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postCheckin: (data) => dispatch(postCheckin(data)),
        requestGeolocation: () => dispatch(requestGeolocation()),
        refreshUser: () => dispatch(refreshUser())
    }
}

export class Checkin extends Component {

    state = {
        checkin: { "items": [] },
        activeStep: 0,
        sending: false,
        show_success: false,
        show_error: false,
        custom_datetime_set: false,
        custom_datetime: undefined
    }

    componentDidMount() {
        this.props.requestGeolocation();
        this.props.refreshUser(); // supports page reload

        // TODO hack
        let checkin_id = window.location.pathname.split("/").pop();
        let checkinData = getCheckinData(checkin_id);

        this.setState({
            checkin: checkinData
        })
    }

    handleSend = () => {
        if (!this.props.userState.online) {
            alert("Sorry, you are offline.");
            return;
        }
        this.setState({
            sending: true
        })

        let lat_lng = null;
        if (this.props.geoState && this.props.geoState.location) {
            const location = this.props.geoState.location;
            lat_lng = location.latitude + "," + location.longitude;
        }
        const data = {
            checkin: this.state.checkin,
            checkin_datetime_utc: this.state.custom_datetime_set ? this.state.custom_datetime.event_datetime_utc : now(),
            checkin_datetime_tz_offset: this.state.custom_datetime_set ? this.state.custom_datetime.event_datetime_tz_offset :clientTzOffset(),
            lat_lng
        };
        this.props.postCheckin(data).then((res) => {
            this.setState({
                sending: false,
                show_success: true,
                activeStep: this.state.activeStep + 1
            });
        }).catch(err => {
            this.setState({
                sending: false,
                show_error: true
            })
        });
    }

    handleNext() {
        if (this.state.activeStep === this.state.checkin.items.length-1) {
            // TODO notify upon success
            this.handleSubmit();
        }
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    };

    handleBack() {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    };

    onItemChanged(item_idx, response_item) {
        const checkin = { ...this.state.checkin }
        if (response_item.type === "slider") {
            checkin.items[item_idx].selected = response_item.value;
        } else if (response_item.type === "notes") {
            checkin.items[item_idx].notes = response_item.value;
        }
        this.setState({checkin: checkin});
        // console.log("onItemChanged", response_item, checkin);
    }

    onCheckinDateChanged(datetime_tz) {
        console.log(datetime_tz);
        const checkin = { ...this.state.checkin }
        checkin.checkin_date = datetime_tz;
        this.setState({
            custom_datetime: datetime_tz,
            checkin: checkin
        })
    }

    render() {
        let content;
        if (this.props.userState.user && this.props.userState.user.name) {

            const checkin_items = this.state.checkin.items;
            content = (
                <div className="home-inner">
                    <Paper square elevation={0} sx={{ mt:2, p: 3 }}>
                        <Typography variant="h5">{this.state.checkin.title}</Typography>
                        <FormControlLabel
                            style={{ marginTop: 10, marginBottom: 10 }}
                            control={<Switch />}
                            onChange={() => this.setState({custom_datetime_set: ! this.state.custom_datetime_set})}
                            label="Custom check-in date" />
                        {this.state.custom_datetime_set ?
                            <EventDateTimePicker dateOnly={true} onChange={(data) => this.onCheckinDateChanged(data)} />
                            : null
                        }
                        <Box sx={{ mt: 2, maxWidth: 400 }}>
                            <Stepper activeStep={this.state.activeStep} orientation="vertical">
                                {checkin_items.map((item, index) => (
                                    <Step key={item.text}>
                                        <StepLabel optional={index === checkin_items.length-1 ? (
                                            <Typography variant="caption">Last step</Typography>
                                            ) : null}>
                                            {item.text}
                                        </StepLabel>
                                        <StepContent>
                                            <CheckinItemSlider
                                                sx={{ width: '90%', ml: 2, mb: 2 }}
                                                item={item}
                                                defaultValue={this.state.checkin.items[index].selected}
                                                onChange={(changeObj) => this.onItemChanged(index, changeObj)}/>
                                            <Box sx={{ mb: 2 }}>
                                                <div>
                                                    <Button
                                                        disabled={index === 0}
                                                        onClick={() => this.handleBack()}
                                                        sx={{ mt: 1, mr: 1 }}>
                                                        Back
                                                    </Button>
                                                    {index === checkin_items.length - 1 ?
                                                    <LoadingButton
                                                        onClick={() => this.handleSend()}
                                                        endIcon={<SendIcon />}
                                                        loading={this.state.sending}
                                                        loadingPosition="end"
                                                        variant="contained">
                                                        Send
                                                    </LoadingButton>
                                                    :
                                                    <Button
                                                        variant="contained"
                                                        size="small"
                                                        onClick={() => this.handleNext()}
                                                        sx={{ mt: 1, mr: 1 }}>
                                                        Continue
                                                    </Button>}

                                                </div>
                                            </Box>
                                        </StepContent>
                                    </Step>
                                ))}
                            </Stepper>
                            {this.state.activeStep === checkin_items.length && (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography>{this.state.checkin.goodbye}</Typography>
                                </Paper>
                            )}
                        </Box>
                    </Paper>
                    <Snackbar open={this.state.show_error} autoHideDuration={6000} onClose={() => this.setState({show_error: false})}>
                        <Alert onClose={() => this.setState({show_error: false})} severity="error" sx={{ width: '100%' }}>
                            Ops. Failed to safe your checkin.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={this.state.show_success} autoHideDuration={3000} onClose={() => this.setState({show_success: false})}>
                        <Alert onClose={() => this.setState({show_success: false})} severity="success" sx={{ width: '100%' }}>
                            Check-in entry saved.
                        </Alert>
                    </Snackbar>
                </div>
            )
        } else {
            content = (
                <div className="auth-inner">
                    <NotLoggedIn />
                </div>
            )
        }
        return (
            <div className="main-wrapper">
                {content}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkin);