import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import TextareaAutosize from '@mui/base/TextareaAutosize';
import {Alert, FormControlLabel, Snackbar, Stack, Switch, Typography} from "@mui/material";
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import "./home.component.css";

import EventDateTimePicker from "./eventdatetime.component";

import {postLog} from "../actions/logs";
import {requestGeolocation} from "../actions/geo";
import {refreshUser} from "../actions/user";
import NotLoggedIn from "./notloggedin.component";
import {clientTzOffset, now} from "../utils/dateutils";
import {getDailyCheckinsTodo, loadTodaysCheckinsForUser} from "../utils/checkinutils";


function mapStateToProps(state) {
    return {
        userState: state.user,
        logsState: state.logs,
        geoState: state.geo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        postLog: (data) => dispatch(postLog(data)),
        requestGeolocation: () => dispatch(requestGeolocation()),
        refreshUser: () => dispatch(refreshUser())
    }
}

export class Home extends Component {

    state = {
        log_text: '',
        use_lat_lng: true,
        custom_datetime: false,
        event_datetime_utc: undefined,
        event_datetime_end_utc: undefined,
        event_datetime_tz_offset: undefined,
        show_success: false,
        daily_checkins: []
    }

    constructor(props) {
        super(props);
        this.state.daily_checkins = getDailyCheckinsTodo();
    }

    keyListenerShiftEnterSubmit = (event) => {
        if (event.key === 'Enter' && event.shiftKey) {
            this.handleSubmit(event);
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.keyListenerShiftEnterSubmit);
        this.props.requestGeolocation();
        this.props.refreshUser().then((userData) => {
            // update checkins
            return loadTodaysCheckinsForUser(this.props.userState.user.public_id).then((data) => {
                if (data) {
                    data.data.checkins.forEach((checkin) => {
                        const j = JSON.parse(checkin.json);
                        const daily_checkings = [...this.state.daily_checkins];
                        for (let i = 0; i < daily_checkings.length; i++) {
                            if (daily_checkings[i].id === j.id) {
                                daily_checkings[i].data = j;
                            }
                        }
                        this.setState({
                            daily_checkings
                        });
                    });
                }
            })
        }).catch((err) => {
            console.log(err);
        })
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyListenerShiftEnterSubmit);
    }

    getLatLng(limitDigits) {
        let lat_lng = null;
        if (this.props.geoState && this.props.geoState.location) {
            const location = this.props.geoState.location;
            if (limitDigits) {
                lat_lng = location.latitude.toFixed(2) + "," + location.longitude.toFixed(2);
            } else {
                lat_lng = location.latitude + "," + location.longitude;
            }
        }
        return lat_lng;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (!this.props.userState.online) {
            alert("Sorry, you are offline.");
        } else {
            if (this.state.log_text) {
                // base attrs
                const data = {
                    text: this.state.log_text,
                    event_datetime_utc: now(),
                    event_datetime_tz_offset: clientTzOffset()
                }
                // lat lng
                let lat_lng = this.getLatLng();
                if (this.state.use_lat_lng && lat_lng) {
                    data.lat_lng = lat_lng;
                }
                // custom datetime
                if (this.state.custom_datetime) {
                    data.event_datetime_utc = this.state.event_datetime_utc;
                    data.event_datetime_end_utc = this.state.event_datetime_end_utc;
                    data.event_datetime_tz_offset = this.state.event_datetime_tz_offset;
                }
                this.props.postLog(data).then(() => {
                    this.setState({
                        log_text: '',
                        show_success: true
                    });
                    // TODO reset custom dates?
                });
            }
        }
    }

    onEventDateTimeChanged(data) {
        this.setState({
            event_datetime_utc: data.event_datetime_utc,
            event_datetime_end_utc: data.event_datetime_end_utc,
            event_datetime_tz_offset: data.event_datetime_tz_offset
        });
    }

    render() {
        let content;

        let lat_lng = null;
        if (this.getLatLng()) {
            lat_lng = "Use lat/lng - " + this.getLatLng(true);
        }

        if (this.props.userState.user) {
            content = (
                <div className="home-inner">
                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Stack spacing={3}>
                                <TextareaAutosize
                                    autoFocus
                                    style={{ width: '100%', padding: '0.5em' }}
                                    aria-label="minimum height"
                                    placeholder="Tell me ..."
                                    minRows={5}
                                    value={this.state.log_text}
                                    onChange={e => this.setState({ log_text: e.target.value})}
                                />
                                {lat_lng ? <FormControlLabel
                                    control={<Switch defaultChecked/>}
                                    onChange={() => this.setState({use_lat_lng: ! this.state.use_lat_lng})}
                                    label={lat_lng} /> : null}
                                <FormControlLabel
                                    style={{ marginTop: 0 }}
                                    control={<Switch />}
                                    onChange={() => this.setState({custom_datetime: ! this.state.custom_datetime})}
                                    label="Custom event date-time" />
                                {this.state.custom_datetime ?
                                    <EventDateTimePicker onChange={(data) => this.onEventDateTimeChanged(data)} />
                                    : null
                                }
                            </Stack>
                            <Button sx={{ mt: 2}} variant="contained" fullWidth onClick={this.handleSubmit}>Log</Button>
                        </CardContent>
                    </Card>
                    {/*<Card sx={{ mt: 2 }}>*/}
                    {/*    <CardContent>*/}
                    {/*        <Typography variant="body1" display="block" fontSize="large">Todays Check-ins</Typography>*/}
                    {/*        {this.state.daily_checkins.map((checkin, index) => {*/}
                    {/*            return (*/}
                    {/*                <Button*/}
                    {/*                    key={index}*/}
                    {/*                    component={Link}*/}
                    {/*                    to={checkin.data !== null ? '/' : checkin.url}*/}
                    {/*                    sx={{ mt: 2}}*/}
                    {/*                    variant={checkin.data !== null ? 'outlined' : 'contained'}*/}
                    {/*                    startIcon={checkin.data !== null ? <CheckCircleIcon /> : null}*/}
                    {/*                    fullWidth>*/}
                    {/*                    {checkin.title}*/}
                    {/*                </Button>*/}
                    {/*            )*/}
                    {/*        })}*/}
                    {/*    </CardContent>*/}
                    {/*</Card>*/}

                    <Snackbar open={this.state.show_success} autoHideDuration={3000} onClose={() => this.setState({show_success: false})}>
                        <Alert onClose={() => this.setState({show_success: false})} severity="success" sx={{ width: '100%' }}>
                            Log entry saved.
                        </Alert>
                    </Snackbar>
                </div>
            )
        } else {
            content = (
                <div className="auth-inner">
                    <NotLoggedIn />
                </div>
            )
        }
        return (
            <div className="main-wrapper">
                {content}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);