import {MEASUREMENT_SLEEP_INTERRUPTIONS} from "./constants";


export class SleepInterruptionsRenderer {

    static type = MEASUREMENT_SLEEP_INTERRUPTIONS;

    static isMatch(measurement) {
        return measurement.type === SleepInterruptionsRenderer.type;
    }

    static getLabelText() {
        return "Sleep Interruptions (min. 10m)";
    }

    static getLabelEmoji() {
        return "🥱";
    }

    static getCssClass(measurement) {
        let css = 'm-0';
        if (measurement) {
            if (measurement.quantity > 60) {
                css = 'm-100';
            } else if (measurement.quantity > 30) {
                css = 'm-75';
            } else if (measurement.quantity > 20) {
                css = 'm-50';
            } else if (measurement.quantity >= 10) {
                css = 'm-25';
            }
        }
        return css;
    }
}
