import React, {Component} from "react";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {Alert, Snackbar, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";

import {loginUser} from "../actions/user";


function mapStateToProps(state) {
    return {
        userState: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loginUser: (username, password) => dispatch(loginUser(username, password))
    }
}

export class Login extends Component {

    state = {
        show_error: false,
        error: {}
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.loginUser(this.username, this.password)
            .catch((err) => {
                this.setState({
                    show_error: true,
                    error: err
                })
            });
    }

    render() {
        if (this.props.userState && this.props.userState.loggedIn) {
            return <Navigate to={'/'} />
        }
        return (
            <div className="main-wrapper">
                <div className="auth-inner">
                    <form onSubmit={this.handleSubmit}>
                        <Card sx={{ mt: 2, mb:1 }}>
                            <CardContent>
                                <Typography sx={{mb: 2}} variant="h5">Login</Typography>

                                <TextField id="username" label="Username" variant="standard" autoFocus
                                           fullWidth
                                           autoComplete="username"
                                           onChange={e => this.username = e.target.value}
                                           onKeyPress={(ev) => {
                                               if (ev.key === 'Enter') {
                                                   ev.preventDefault();
                                                   this.handleSubmit(ev);
                                               }
                                           }}/>

                                <TextField id="password" label="Password" variant="standard"
                                           type="password"
                                           fullWidth
                                           sx={{mt: 1}}
                                           autoComplete="current-password"
                                           onChange={e => this.password = e.target.value}
                                           onKeyPress={(ev) => {
                                               if (ev.key === 'Enter') {
                                                   ev.preventDefault();
                                                   this.handleSubmit(ev);
                                               }
                                           }}/>

                                <Button sx={{ mt: 4}} variant="contained" fullWidth onClick={this.handleSubmit}>Login</Button>
                            </CardContent>
                        </Card>
                    </form>
                    <Snackbar open={this.state.show_error} autoHideDuration={6000} onClose={() => this.setState({show_error: false})}>
                        <Alert onClose={() => this.setState({show_error: false})} severity="error" sx={{ width: '100%' }}>
                            {this.state.error.status === 401 ? "Invalid login." : "Sorry, server issues."}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);