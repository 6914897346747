import {
    MEASUREMENTS_FETCH_STARTED,
    MEASUREMENTS_FETCH_SUCCESS,
    MEASUREMENTS_FETCH_FAILURE
} from "./index";

import {getAxios} from '../utils/apiutils';
import {processMeasurementItem} from "../utils/measurementutils";


export const fetchMeasurements = (filter_term) => {
    return dispatch => {
        dispatch(fetchMeasurementsStarted());
        let data = {
            'filter_term': filter_term,
            'nr_logs': 10000
        };
        return getAxios().then(instance => {
            return instance.post('measurement_query', data).then(
                    res => {
                        const m = res.data.measurements.map(processMeasurementItem);
                        dispatch(fetchMeasurementsSuccess(m));
                        return Promise.resolve(m);
                    }
                ).catch(
                    err => {
                        dispatch(fetchMeasurementsFailure(err.message));
                        return Promise.reject(err);
                    }
                )
        }).catch(
            err => {
                dispatch(fetchMeasurementsFailure(err.message));
                return Promise.reject(err);
            }
        )
    }
}

const fetchMeasurementsStarted = () => {
    return {
        type: MEASUREMENTS_FETCH_STARTED
    }
}

const fetchMeasurementsSuccess = (data) => {
    return {
        type: MEASUREMENTS_FETCH_SUCCESS,
        payload: data
    }
}

const fetchMeasurementsFailure = (err) => {
    return {
        type: MEASUREMENTS_FETCH_FAILURE,
        payload: err
    }
}
