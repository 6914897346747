import {
    USER_LOGIN_STARTED,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAILURE,
    LOG_OUT,
    USER_REGISTER_SUCCESS,
    WENT_ONLINE, WENT_OFFLINE
} from "../actions";

import { storeUser } from "../utils/userutils"


let initialState = {
    loading: false,
    user: null,
    registered: false,
    loggedIn: false,
    error: null,
    online: true
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN_STARTED:
            return {
                ...state,
                loading: true
            };
        case USER_LOGIN_SUCCESS:
            let userData = {
                token: action.payload.token,
                exp_datetime_utc: action.payload.exp_datetime_utc,
                user: {
                    name: action.payload.user.name,
                    public_id: action.payload.user.public_id
                }
            };
            storeUser(userData);
            return {
                ...state,
                loading: false,
                loggedIn: true,
                error: null,
                user: userData.user
            };
        case USER_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                loggedIn: false,
                error: action.payload
            };
        case LOG_OUT:
            return {
                ...state,
                loading: false,
                registered: false,
                error: null,
                loggedIn: false,
                user: null
            };
        case USER_REGISTER_SUCCESS:
            return {
                ...state,
                registered: true
            }
        case WENT_ONLINE:
            return {
                ...state,
                online: true
            };
        case WENT_OFFLINE:
            return {
                ...state,
                online: false
            };
        default:
            return state;
    }
};

export default userReducer;