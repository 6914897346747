import React, {Component} from "react";
import {registerUser} from "../actions/user";
import {Navigate} from "react-router-dom";
import {connect} from "react-redux";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Alert, Snackbar, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


function mapStateToProps(state) {
    return {
        userState: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        registerUser: (username, email, password) => dispatch(registerUser(username, email, password))
    }
}


export class Register extends Component {

    state = {
        show_error: false,
        error: {}
    }

    handleSubmit = e => {
        e.preventDefault();
        if (this.password !== this.confirmPassword) {
            this.setState({
                show_error: true,
                error: "Passwords do not match!"
            })
        } else {
            this.props.registerUser(this.userName, this.email, this.password).then((res) => {

            }).catch((err) => {
                this.setState({
                    show_error: true,
                    error: err
                })
            });;
        }
    }

    render() {
        if (this.props.userState && this.props.userState.registered) {
            return <Navigate to={'/login'} />
        }
        return (
            <div className="main-wrapper">
                <div className="auth-inner">
                    <form onSubmit={this.handleSubmit}>
                        <Card sx={{ mt: 2, mb:1 }}>
                            <CardContent>
                                <Typography sx={{mb: 2}} variant="h5">Sign Up</Typography>

                                <TextField id="username" label="Username" variant="standard" autoFocus
                                           fullWidth
                                           autoComplete="username"
                                           onChange={e => this.userName = e.target.value}/>

                                <TextField id="email" label="Email" variant="standard"
                                           type="email"
                                           fullWidth
                                           sx={{mt: 1}}
                                           onChange={e => this.email = e.target.value}/>

                                <TextField id="password" label="Password" variant="standard"
                                           type="password"
                                           fullWidth
                                           sx={{mt: 1}}
                                           autoComplete="current-password"
                                           onChange={e => this.password = e.target.value}/>

                                <TextField id="confirm-password" label="Confirm Password" variant="standard"
                                           type="password"
                                           fullWidth
                                           sx={{mt: 1}}
                                           autoComplete="current-password"
                                           onChange={e => this.confirmPassword = e.target.value}/>

                                <Button sx={{ mt: 4}} variant="contained" fullWidth onClick={this.handleSubmit}>Sign Up</Button>
                            </CardContent>
                        </Card>
                    </form>
                </div>
                <Snackbar open={this.state.show_error} autoHideDuration={6000} onClose={() => this.setState({show_error: false})}>
                    <Alert onClose={() => this.setState({show_error: false})} severity="error" sx={{ width: '100%' }}>
                        {this.state.error}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);