// user action types

export const USER_LOGIN_STARTED = "LOGIN_USER_STARTED";
export const USER_LOGIN_SUCCESS = "LOGIN_USER_SUCCESS";
export const USER_LOGIN_FAILURE = "LOGIN_USER_FAILURE";
export const LOG_OUT = "LOG_OUT";

export const USER_REGISTER_STARTED = "USER_REGISTER_STARTED";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";

export const WENT_ONLINE = "WENT_ONLINE";
export const WENT_OFFLINE = "WENT_OFFLINE";


// logs action types

export const LOGS_FETCH_STARTED = "LOGS_FETCH_STARTED";
export const LOGS_FETCH_SUCCESS = "LOGS_FETCH_SUCCESS";
export const LOGS_FETCH_FAILURE = "LOGS_FETCH_FAILURE";

export const LOGS_POST_STARTED = "LOGS_POST_STARTED";
export const LOGS_POST_SUCCESS = "LOGS_POST_SUCCESS";
export const LOGS_POST_FAILURE = "LOGS_POST_FAILURE";

export const LOGS_UPDATE_STARTED = "LOGS_UPDATE_STARTED";
export const LOGS_UPDATE_SUCCESS = "LOGS_UPDATE_SUCCESS";
export const LOGS_UPDATE_FAILURE = "LOGS_UPDATE_FAILURE";

export const CHECKIN_POST_STARTED = "CHECKIN_POST_STARTED";
export const CHECKIN_POST_SUCCESS = "CHECKIN_POST_SUCCESS";
export const CHECKIN_POST_FAILURE = "CHECKIN_POST_FAILURE";

export const CHECKIN_FETCH_STARTED = "CHECKIN_FETCH_STARTED";
export const CHECKIN_FETCH_SUCCESS = "CHECKIN_FETCH_SUCCESS";
export const CHECKIN_FETCH_FAILURE = "CHECKIN_FETCH_FAILURE";


// measurements action types

export const MEASUREMENTS_FETCH_STARTED = "MEASUREMENTS_FETCH_STARTED";
export const MEASUREMENTS_FETCH_SUCCESS = "MEASUREMENTS_FETCH_SUCCESS";
export const MEASUREMENTS_FETCH_FAILURE = "MEASUREMENTS_FETCH_FAILURE";


// misc

export const GEO_REQUEST_LOCATION_STARTED = "GEO_REQUEST_LOCATION_STARTED";
export const GEO_REQUEST_LOCATION_SUCCESS = "GEO_REQUEST_LOCATION_SUCCESS";
export const GEO_REQUEST_LOCATION_FAILURE = "GEO_REQUEST_LOCATION_FAILURE";

// error types

export const ERR_NOT_LOGGED_IN = "ERR_NOT_LOGGED_IN";
export const ERR_LOGIN = "ERR_LOGIN";


