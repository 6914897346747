import {NapRenderer} from "./nap";
import {AlcoholRenderer} from "./alcohol";
import {SleepInterruptionsRenderer} from "./sleep";


export const registry = {
    [NapRenderer.type] : NapRenderer,
    [AlcoholRenderer.type] : AlcoholRenderer,
    [SleepInterruptionsRenderer.type] : SleepInterruptionsRenderer
}



