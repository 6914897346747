import axios from "axios";
import apiBaseUrl from "../config";
import {loadCredentials, loadUser, storeUser, deleteUser, willTokenExpireSoon} from "./userutils";
import {ERR_LOGIN} from "../actions";


const instance = axios.create({
    baseURL: apiBaseUrl
});


/**
 * @returns {AxiosInstance} with valid auth header token set if token not expired yet.
 */
export const getAxiosInstance = () => {
    const userData = loadUser();
    if (userData) {
        const token = userData.token;
        instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
        instance.defaults.headers.common['x-access-token'] = token;
    }
    return instance
};

/**
 * Returns a Promise resolved with an axios instance with valid JWT token.
 * Performs auto-login if token is expired or about to expire.
 * Returns a Promise rejected with an error message if auto-login fails or credentials are not found.
 *
 * @return {Promise} with axios instance with valid auth header or reject with error
 */
export const getAxios = () => {
    const instance = getAxiosInstance();

    if (!willTokenExpireSoon()) {
        console.log("axios instance - token not expired yet");
        return Promise.resolve(instance);
    }

    return instance.get('ping').then(
        res => {
            console.log("axios instance - ping, valid token");
            return Promise.resolve(instance);
        }
    ).catch(
        err => {
            const cred = loadCredentials();
            if (!cred) {
                const msg = {
                    type: ERR_LOGIN,
                    status: 401,
                    msg: 'no credentials'
                };
                console.log("axios instance - no credentials");
                return Promise.reject(msg);
            }
            const data = {
                name: cred.username,
                password: cred.password
            }
            console.log("axios instance - auto-login attempt");
            return getAxiosInstance().post('login', data).then(
                res => {
                    const action = res.data;
                    console.log("axios instance - re-logging in");
                    let userData = {
                        token: action.token,
                        exp_datetime_utc: action.exp_datetime_utc,
                        user: {
                            name: action.user.name,
                            public_id: action.user.public_id
                        }
                    };
                    storeUser(userData);
                    console.log("axios instance - successfully re-logged in");
                    return Promise.resolve(getAxiosInstance());
                }
            ).catch(
                err => {
                    console.log("axios instance - re-login failed", err);
                    const msg = {
                        type: ERR_LOGIN,
                        status: err.response,
                        msg: err.response.data
                    };
                    deleteUser();
                    return Promise.reject(msg);
                }
            )
        }
    )
}