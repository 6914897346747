import {
    MEASUREMENTS_FETCH_FAILURE,
    MEASUREMENTS_FETCH_STARTED,
    MEASUREMENTS_FETCH_SUCCESS
} from "../actions";


let initialState = {
    loading: false,
    measurements: [],
    error: null
};

const measurementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case MEASUREMENTS_FETCH_STARTED:
            return {
                ...state,
                loading: true
            };
        case MEASUREMENTS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                measurements: action.payload
            }
        case MEASUREMENTS_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                logs: [],
                error: action.payload,
            }

        default:
            return state;
    }
};

export default measurementsReducer;