import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Component} from "react";
import {connect} from "react-redux";

import './App.css';

import Home from './components/home.component';
import Nav from './components/nav.component';
import Login from './components/login.component';
import Register from './components/register.component';
import Search from "./components/search.component";
import Stats from "./components/stats.component";
import Checkin from "./components/checkin.component";

import {setOffline, setOnline} from "./actions/user";

function mapDispatchToProps(dispatch) {
    return {
        setOnline: () => dispatch(setOnline()),
        setOffline: () => dispatch(setOffline())
    }
}

export class App extends Component {

    componentDidMount() {
        if (navigator.onLine) {
            this.props.setOnline();
        } else {
            this.props.setOffline();
        }
        window.addEventListener('offline', (e) => {
            this.props.setOffline();
        });
        window.addEventListener('online', (e) => {
            this.props.setOnline();
        });
        console.log("registered online/offline listener");
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Nav/>
                    <Routes>
                        <Route exact path="/" element={<Home />} />
                        <Route exact path="/stats" element={<Stats />} />
                        <Route exact path="/search" element={<Search />} />
                        <Route exact path="/checkin/:id" element={<Checkin />} />
                        <Route exact path="/login" element={<Login />} />
                        <Route exact path="/register" element={<Register/>} />
                    </Routes>
                </div>
            </BrowserRouter>
        );
    }

}

export default connect(null, mapDispatchToProps)(App);
