import {
    GEO_REQUEST_LOCATION_STARTED,
    GEO_REQUEST_LOCATION_SUCCESS,
    GEO_REQUEST_LOCATION_FAILURE
} from "./index";



export const requestGeolocation = () => {
    return dispatch => {
        dispatch(geoRequestStarted());
        if(!navigator.geolocation) {
            dispatch(geoRequestFailure('Geolocation is not supported by browser'));
        } else {
            navigator.geolocation.getCurrentPosition((position) => {
                dispatch(geoRequestSuccess({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }));
            }, (err) => {
                dispatch(geoRequestFailure(err));
            });
        }
    }
}

const geoRequestStarted = () => {
    return {
        type: GEO_REQUEST_LOCATION_STARTED
    }
}

const geoRequestSuccess = (data) => {
    return {
        type: GEO_REQUEST_LOCATION_SUCCESS,
        payload: data
    }
}

const geoRequestFailure = (err) => {
    return {
        type: GEO_REQUEST_LOCATION_FAILURE,
        payload: err
    }
}
