import {toDateString, toTimezoneDatetime} from "./dateutils";



// central function to process a measurement item coming from server

export const processMeasurementItem = (item) => {
    const measurement_datetime_utc = toTimezoneDatetime(item.measurement_datetime_utc, item.measurement_datetime_tz_offset);
    const measurement_datetime_tz_offset = item.measurement_datetime_tz_offset;

    const new_item = {
        ...item,
        measurement_datetime_utc: measurement_datetime_utc,
        measurement_datetime_tz_offset: measurement_datetime_tz_offset
    };
    return new_item;
};


export const toDataFrame = (measurements) => {
    let df = {};
    let columns = [];
    measurements.forEach((m) => {
        let t = m.type;
        if (!columns.includes(t)) {
            columns.push(t);
        }
        if (!df[t]) {
            df[t] = {}
        }
        const date = toDateString(m.measurement_datetime_utc);
        df[t][date] = m
    })
    return {
        "columns": columns,
        "data": df
    };
}

