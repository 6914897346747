import {
    LOGS_FETCH_STARTED,
    LOGS_FETCH_SUCCESS,
    LOGS_FETCH_FAILURE,
    LOGS_POST_STARTED,
    LOGS_POST_SUCCESS,
    LOGS_POST_FAILURE, LOGS_UPDATE_STARTED, LOGS_UPDATE_SUCCESS, LOGS_UPDATE_FAILURE
} from "../actions";
import {parseLogItem} from "../utils/logutils";

let initialState = {
    loading: false,
    logs: [],
    checkins: [],
    error: null
};

const logsReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGS_FETCH_STARTED:
            return {
                ...state,
                loading: true
            };
        case LOGS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                logs: action.payload.map(parseLogItem)
            }
        case LOGS_FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                logs: [],
                error: action.payload,
            }
        case LOGS_POST_STARTED:
            return {
                ...state,
                loading: true
            };
        case LOGS_POST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                logs: [ parseLogItem(action.payload.log), ...state.logs ]
            }
        case LOGS_POST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case LOGS_UPDATE_STARTED:
            return {
                ...state,
                loading: true
            };
        case LOGS_UPDATE_SUCCESS:
            const updatedItem = parseLogItem(action.payload.log);
            let updatedLogs = state.logs.map((item) => {
                if (updatedItem.id === item.id) {
                    return updatedItem
                } else {
                    return item
                }
            })
            return {
                ...state,
                loading: false,
                error: null,
                logs: [ ...updatedLogs ]
            }
        case LOGS_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
};

export default logsReducer;