import React, {Component} from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {getTimezones} from "../utils/utils";


class TimezonePicker extends Component {

    state = {
        tz_selected_idx: 320 // 320 Berlin, 132 Los Angeles
    }

    constructor(props) {
        super(props);
        this.timezones = getTimezones();

        if (props.initialOffsetInMinutes !== undefined) {
            // init timezone by guessing from offset
            for (let i = 0; i < this.timezones.length; i++) {
                if (this.timezones[i].offset === props.initialOffsetInMinutes) {
                    this.state.tz_selected_idx = i;
                    break;
                }
            }
        }
    }

    tzChanged(e) {
        let idx = e.value;
        this.setState({
            tz_selected_idx: idx
        })
        this.props.onTimezoneChanged(this.timezones[idx]);
    }

    formatOffset(offset_mins) {
        const hrs = -offset_mins / 60;
        if (hrs > 0) {
            return "GMT+"+hrs;
        } else {
            return "GMT"+hrs;
        }
    }

    render() {
        return (
            <FormControl>
                <InputLabel id="demo-simple-select-label">Timezone</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.tz_selected_idx}
                    label="Timezone"
                    onChange={(e) => this.tzChanged(e.target)}>
                    {this.timezones.map((tz, i) => <MenuItem key={i} value={i}>{tz.name} ({this.formatOffset(tz.offset)})</MenuItem>)}
                </Select>
            </FormControl>
        )
    }

}

export default TimezonePicker;