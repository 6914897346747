import {MEASUREMENT_NAP} from "./constants";


export class NapRenderer {

    static type = MEASUREMENT_NAP;

    static isMatch(measurement) {
        return measurement.type === NapRenderer.type;
    }

    static getLabelText() {
        return "Naps";
    }

    static getLabelEmoji() {
        return "😴";
    }

    static getCssClass(measurement) {
        let css = 'm-0';
        if (measurement && measurement.quantity > 0) {
            css = 'm-100';
        }
        return css;
    }
}
