import React, {Component} from "react";
import {connect} from "react-redux";

import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import BarChartIcon from '@mui/icons-material/BarChart';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import Button from "@mui/material/Button";

import {Link} from "react-router-dom";
import {logOut} from "../actions/user";


function mapStateToProps(state) {
    return {
        userState: state.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        logOut: () => dispatch(logOut())
    }
}


export class Nav extends Component {

    handleLogout = () => {
        localStorage.clear();
        this.props.logOut();
        console.log("Logged out.");
    }

    render() {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed">
                    <Toolbar>
                        <Button color="inherit" size="large" component={Link} to="/">Me Myself & AI</Button>
                        <Box sx={{ flexGrow: 1 }}/>
                        {
                            this.props.userState.user ?
                            <Box>
                                <IconButton aria-label="login" color="inherit" component={Link} to="/checkin/evening-checkin-v2">
                                    <CheckCircleRoundedIcon />
                                </IconButton>
                                <IconButton aria-label="login" color="inherit" component={Link} to="/stats">
                                    <BarChartIcon />
                                </IconButton>
                                <IconButton aria-label="login" color="inherit" component={Link} to="/search">
                                    <SearchIcon />
                                </IconButton>
                                <IconButton aria-label="login" color="inherit" onClick={() => this.handleLogout()}>
                                    <LogoutIcon />
                                </IconButton>
                            </Box>
                            :
                            <Box>
                                <IconButton aria-label="login" color="inherit" component={Link} to="/login">
                                    <LoginIcon />
                                </IconButton>
                                <Button color="inherit" component={Link} to="/register">Sign&nbsp;Up</Button>
                            </Box>
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);