import {
    GEO_REQUEST_LOCATION_STARTED,
    GEO_REQUEST_LOCATION_SUCCESS,
    GEO_REQUEST_LOCATION_FAILURE
} from "../actions";

let initialState = {
    loading: false,
    location: null,
    error: null
};

const geoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GEO_REQUEST_LOCATION_STARTED:
            return {
                ...state,
                loading: true
            };
        case GEO_REQUEST_LOCATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                location: action.payload
            };
        case GEO_REQUEST_LOCATION_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
};

export default geoReducer;