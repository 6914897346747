import {MEASUREMENT_ALCOHOL} from "./constants";


export class AlcoholRenderer {

    static type = MEASUREMENT_ALCOHOL;

    static isMatch(measurement) {
        return measurement.type === AlcoholRenderer.type;
    }

    static getLabelText() {
        return "Alcohol";
    }

    static getLabelEmoji() {
        return "🍺";
    }

    static getCssClass(measurement) {
        let css = 'm-0';
        if (measurement) {
            if (measurement.quantity > 3) {
                css = 'm-100';
            } else if (measurement.quantity > 2) {
                css = 'm-75';
            } else if (measurement.quantity > 1) {
                css = 'm-50';
            } else if (measurement.quantity > 0) {
                css = 'm-25';
            }
        }
        return css;
    }
}