import moment from "moment";

const USER_KEY = 'mm_user';
const USER_CREDENTIALS = 'mm_auth';


// user information and token

export const loadUser = () => {
    const userString = localStorage.getItem(USER_KEY);
    if (userString) {
        const userJson = JSON.parse(userString);
        if (moment(userJson.exp_datetime_utc) > moment()) {
            return userJson;
        } else {
            deleteUser();
            console.log("Token expired.");
        }
    }
    return undefined;
};

/**
 * @param minutesInFuture
 * @returns {boolean} true if no token or token will expire in less than minutesInFuture minutes
 */
export const willTokenExpireSoon = (minutesInFuture = 5) => {
    const userString = localStorage.getItem(USER_KEY);
    if (userString) {
        const userJson = JSON.parse(userString);
        let exp_datetime_utc = moment(userJson.exp_datetime_utc);
        if (exp_datetime_utc < moment().add(minutesInFuture, 'minutes')) {
            return false;
        }
    }
    return true;
}

export const deleteUser = () => {
    let userString = localStorage.getItem(USER_KEY);
    if (userString) {
        localStorage.removeItem(USER_KEY);
    }
};

export const storeUser = (userData) => {
    localStorage.setItem(USER_KEY, JSON.stringify(userData));
};


// credentials

export const loadCredentials = () => {
    const credString = localStorage.getItem(USER_CREDENTIALS);
    if (credString) {
        return JSON.parse(credString);
    }
    return undefined;
};

export const storeCredentials = (username, password) => {
    localStorage.setItem(USER_CREDENTIALS, JSON.stringify({username: username, password: password}));
};

export const deleteCredentials = () => {
    let credString = localStorage.getItem(USER_CREDENTIALS);
    if (credString) {
        localStorage.removeItem(USER_CREDENTIALS);
    }
};