import React, {Component} from "react";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Typography} from "@mui/material";


export class Loading extends Component {

    render() {
        return (
            <Card sx={{ mt: 2, mb:1 }}>
                <CardContent>
                    <Typography variant="body1">Loading ...</Typography>
                </CardContent>
            </Card>
        )
    }
}

export default Loading;