import {
    USER_LOGIN_STARTED, USER_LOGIN_SUCCESS, USER_LOGIN_FAILURE,
    LOG_OUT,
    USER_REGISTER_STARTED, USER_REGISTER_SUCCESS, USER_REGISTER_FAILURE,
    WENT_ONLINE, WENT_OFFLINE,
    ERR_NOT_LOGGED_IN, ERR_LOGIN
} from "./index";
import {loadUser, deleteUser, deleteCredentials, loadCredentials, storeCredentials} from "../utils/userutils";

import {getAxiosInstance} from '../utils/apiutils';


// login / logout

export const loginUser = (username, password) => {
    return dispatch => {
        dispatch(loginUserStarted());
        const data = {
            name: username,
            password: password
        }
        return getAxiosInstance().post('login', data).then(
            res => {
                dispatch(loginUserSuccess(res.data));
                storeCredentials(username, password);
                return Promise.resolve(res);
            }
        ).catch(
            err => {
                const msg = {
                    type: ERR_LOGIN,
                    status: err.response.status,
                    msg: err.response.data
                };
                dispatch(loginUserFailure(msg));
                return Promise.reject(msg);
            }
        )
    }
}

export const refreshUser = () => {
    return dispatch => {
        const userData = loadUser();
        // check credentials are valid
        if (userData) {
            dispatch(loginUserSuccess(userData));
            return Promise.resolve(userData);
        } else {
            const cred = loadCredentials();
            if (cred) {
                return loginUser(cred.username, cred.password)(dispatch);
            } else {
                const err = {
                    type: ERR_NOT_LOGGED_IN,
                    msg: "Not logged in."
                };
                dispatch(loginUserFailure(err));
                return Promise.reject(err);
            }
        }
    }
}

const loginUserStarted = () => {
    return {
        type: USER_LOGIN_STARTED
    }
}

const loginUserSuccess = (data) => {
    return {
        type: USER_LOGIN_SUCCESS,
        payload: data
    }
}

const loginUserFailure = (err) => {
    return {
        type: USER_LOGIN_FAILURE,
        payload: err
    }
}

export const logOut = () => {
    deleteUser();
    deleteCredentials();
    return {
        type: LOG_OUT
    }
}


// registration

export const registerUser = (username, email, password) => {
    return dispatch => {
        dispatch(registerUserStarted());
        // TODO send hash not clear text password
        const data = {
            name: username,
            email: email,
            password: password,
            password_confirm: password
        }
        return getAxiosInstance().post('user', data).then(
            res => {
                dispatch(registerUserSuccess(res.data));
                return Promise.resolve(res.data);
            }
        ).catch(
            err => {
                dispatch(registerUserFailure(err.message));
                return Promise.reject(err.message);
            }
        )
    }
}

const registerUserStarted = () => {
    return {
        type: USER_REGISTER_STARTED
    }
}

const registerUserSuccess = (data) => {
    return {
        type: USER_REGISTER_SUCCESS,
        payload: data
    }
}

const registerUserFailure = (err) => {
    return {
        type: USER_REGISTER_FAILURE,
        payload: err
    }
}


// online / offline

export const setOnline = () => {
    return dispatch => {
        dispatch({ type: WENT_ONLINE });
    }
}

export const setOffline = () => {
    return dispatch => {
        dispatch({ type: WENT_OFFLINE });
    }
}