import React, {Component} from "react";
import {Link} from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Typography} from "@mui/material";


export class NotLoggedIn extends Component {

    render() {
        return (
            <Card sx={{ mt: 2, mb:1 }}>
                <CardContent>
                    <Typography variant="h5">Not logged in.</Typography>
                    <Typography sx={{mt: 1}} variant="body1">Log in <Link to={'/login'}>here</Link>.</Typography>
                </CardContent>
            </Card>
        )
    }
}

export default NotLoggedIn;