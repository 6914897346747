import {getAxios} from "./apiutils";

import morningV1 from './checkins/morning-v1.json';
import eveningV1 from './checkins/evening-v1.json';
import eveningV2 from './checkins/evening-v2.json';


export const getCheckinData = (checkin_id) => {
    switch(checkin_id) {
        case 'morning-checkin-v1': return morningV1;
        case 'evening-checkin-v1': return eveningV1;
        case 'evening-checkin-v2': return eveningV2;
        default: return undefined;
    }
};

const morning_checkin_url = '/checkin/morning-checkin-v1';
const evening_checkin_url = '/checkin/evening-checkin-v1';

export const getDailyCheckinsTodo = () => {
    return [
        {
            title: 'Morning 🌞',
            url: morning_checkin_url,
            id: 'morning-checkin-v1',
            data: null
        },
        {
            title: 'End of Day 🌜',
            url: evening_checkin_url,
            id: 'evening-checkin-v1',
            data: null
        }
    ]
}

export const loadTodaysCheckinsForUser = (user_id) => {
    return getAxios().then(instance => {
        instance.get('/checkin/user/'+user_id);
    }).catch(err => {
        console.log('failed to load checkins', err);
    });
}
