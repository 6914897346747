import moment from "moment-timezone";


export const sortByKey = (array, key) => {
    return array.sort(function(a, b) {
        const x = a[key];
        const y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}

export const getTimezones = () => {
    const countries = moment.tz.countries();
    let timezones_names = [];
    let timezones = [];
    countries.forEach((country) => {
        const zones = moment.tz.zonesForCountry(country, true);
        zones.forEach((zone) => {
            if (!timezones_names.includes(zone.name)) {
                timezones.push(zone);
                timezones_names.push(zone.name);
            }
        });
    });
    return sortByKey(timezones, "name");
}
