import {toRelativeDatetime, toTimezoneDatetime} from "./dateutils";


// central function to process a log item
export const parseLogItem = (item) => {
    const hashtag = extractHashTag(item.text);
    const timeInfo = extractTimeInfo(item.text);
    const textBody = trimText(item.text, hashtag, timeInfo);
    const event_datetime_utc = toTimezoneDatetime(item.event_datetime_utc, item.event_datetime_tz_offset);
    const event_datetime_end_utc = toTimezoneDatetime(item.event_datetime_end_utc, item.event_datetime_tz_offset);
    const event_datetime_tz_offset = item.event_datetime_tz_offset;
    const relativeDatetime = toRelativeDatetime(event_datetime_utc);

    const new_item = {
        ...item,
        hashtag,
        timeInfo,
        textBody,
        relativeDatetime,
        event_datetime_utc,
        event_datetime_tz_offset,
        event_datetime_end_utc
    };
    return new_item;
};

const hashtag_re = /#\w+/;

const extractHashTag = (text) => {
    let res = undefined;
    const match = text.match(hashtag_re);
    if (match) {
        res = match[0];
    }
    return res;
}

const timerange_re = /\d{1,2}(:\d{1,2})?(-\d{1,2}(:\d{1,2})?)?[ap]m/;

const extractTimeInfo = (text) => {
    let res = undefined;
    const match = text.match(timerange_re);
    if (match) {
        res = match[0];
    }
    return res;
}

const trimText = (text, hashtag, time_info) => {
    // console.log(text, "--", hashtag, "--", timeinfo);
    return text.replace(hashtag, "")
        .replace(time_info, "")
        .replace(/\s{2,}/g, " ")
        .replace(/ ,/, ",")
        .replace(/^\s*,?\s*/, "")
}
